import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config"


const API_URL = ConfigService.base_url;

class QrCodeServiceApi {
      getData(data) {
        return axios
          .post(API_URL +'admin/generatejsonqr',data,
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
  
}



export default new QrCodeServiceApi ()