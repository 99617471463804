import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config";

const API_URL = ConfigService.base_url;

class TradeServiceApi {
  getAllActiveTrade(page,paginate=10) {
    return axios
      .get(API_URL + "admin/trades", {
        params:{ 
          type:'accepted',
          paginate:paginate,
          page:page
        },
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAllTradeHistory(page,paginate=10,search=null) {
    return axios
      .get(API_URL + "admin/trades", {
        params:{ 
          type:'history',
          paginate:paginate,
          page:page,
          email:search
        },
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAllTrades(arg){
    return axios
    .get(API_URL + "admin/trades", {
      params:arg,
      headers: authHeader()
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  }
  updateTrade(data){
    return axios
    .post(API_URL + "trade", data,{
      headers: authHeader()
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  }
  verifyTrade(data){
    return axios
      .post(API_URL + "admin/verifytrade", 
      data,
      {
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

}

export default new TradeServiceApi();
