<script>
export default {
  props: ["show"],
  data() {
    return {};
  },
};
</script>

<template>
  <div v-if="show">
    <div style="margin:auto;width:145px;">
      <b-spinner
        type="grow"
        class="m-2"
        variant="warning"
        role="status"
      ></b-spinner>
      <b-spinner
        type="grow"
        class="m-2"
        variant="danger"
        role="status"
      ></b-spinner>
      <b-spinner
        type="grow"
        class="m-2"
        variant="dark"
        role="status"
      ></b-spinner>
    </div>
  </div>
</template>
