<script>
import QrcodeVue from "qrcode.vue";
import print from "vue-print-nb";

export default {
  props: ["showQR", "qrData", "qrGenerating"],
  directives: {
    print: print,
  },
  data() {
    return {
      size: 64,
      printObj: {
        id: "printQR",
        popTitle: "QR Code print",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback(vue) {
          vue.printLoading = true;
        },
        openCallback(vue) {
          vue.printLoading = false;
        },
        closeCallback() {},
      },
    };
  },
  methods: {
    hideModal() {
      this.$emit("resetQR", true);
    },
  },
  computed: {
    qrCode() {
      return JSON.stringify(this.qrData);
    },
  },
  components: {
    QrcodeVue,
  },
  watch: {
    showQR() {
      if (this.showQR) {
        this.$refs["viewBarcode"].show();
      }
    },
  },
};
</script>
<template>
  <b-modal
    ref="viewBarcode"
    id="viewBarcode"
    centered
    size="md"
    @hidden="hideModal"
    hide-footer
  >
    <div v-if="qrGenerating && qrData">
      <div id="printQR">
      <qrcode-vue :value="qrCode" :size="size" level="H" />
      </div>
      <button v-print="printObj" class="btn btn-danger print-btn">
        <i class="ri-printer-line"></i>
        Print
      </button>
    </div>

    <div v-else>
      <img
        src="@/assets/images/qr-loading.gif"
        alt="Loading..."
        srcset=""
        style="margin: auto; display: block"
        width="64"
      />
    </div>
  </b-modal>
</template>

<style>
#viewBarcode .modal-content {
  padding-bottom: 24px;
}
.print-btn {
  margin: auto;
  display: block;
  margin-top: 64px;
}
#printQR {
  margin: auto;
  width: max-content;
  margin-top: 64px;
  margin-bottom: 64px;
}
</style>
